import { Action, createReducer, on } from '@ngrx/store';

import { onLoadInvoiceDetails } from '../../helpers/on-load-invoice-details';
import { InvoiceDetails, InvoiceTotals } from '../../models';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.createRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.createSuccess, (state, { details }) => ({
    ...state,
    ...onLoadTemporaryInvoice(details),
    isLoading: false,
    error: null,
  })),
  on(fromActions.createFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.createInvoiceRejectedRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.createInvoiceRejectedSuccess, (state, { details }) => ({
    ...state,
    ...onLoadTemporaryInvoice(details),
    isLoading: false,
    error: null,
  })),
  on(fromActions.createInvoiceRejectedFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.updateOverrideRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.updateOverrideSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.updateOverrideFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.removeOverrideRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.removeOverrideSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.removeOverrideFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.registerRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.registerSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.registerFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.updateRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.updateSuccess, (state, { details }) => {
    return {
      ...state,
      ...onLoadTemporaryInvoice(details),
      isLoading: false,
      error: null,
    };
  }),
  on(fromActions.updateFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.updateRowRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.updateRowSuccess, (state) => {
    return {
      ...state,
      isLoading: false,
      error: null,
    };
  }),
  on(fromActions.updateRowFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.addRowRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.addRowSuccess, (state) => {
    return {
      ...state,
      isLoading: false,
      error: null,
    };
  }),
  on(fromActions.addRowFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.removeRowRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.removeRowSuccess, (state) => {
    return {
      ...state,
      isLoading: false,
      error: null,
    };
  }),
  on(fromActions.removeRowFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.createOverrideRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.createOverrideSuccess, (state) => {
    return {
      ...state,
      isLoading: false,
      error: null,
    };
  }),
  on(fromActions.createOverrideFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { details }) => {
    return {
      ...state,
      ...onLoadTemporaryInvoice(details),
      error: null,
      isLoading: false,
    };
  }),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.updateInvoiceTotals, (state, { totals }) => ({
    ...state,
    totals: {
      ...state.totals,
      ...totals,
    },
  })),

  on(fromActions.setUsingHolidayVoucher, (state, { usingHolidayVoucher }) => ({
    ...state,
    usingHolidayVoucher,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function temporaryInvoiceReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}

function onLoadTemporaryInvoice(temporaryInvoice: InvoiceDetails): {
  details: InvoiceDetails;
  totals: InvoiceTotals;
} {
  const [details, totals] = onLoadInvoiceDetails(temporaryInvoice);

  const reservation_payments = details.reservation_payments.map((payment) => ({
    ...payment,
    date: payment['registration_date'],
  }));

  const newTotals = {
    ...totals,
    paid: details?.balance_available || totals?.paid,
    to_pay: details?.balance_available ? 0 : totals?.to_pay,
  };

  return {
    details: { ...details, reservation_payments, temporary: true },
    totals: newTotals,
  };
}
