import { createAction, props } from '@ngrx/store';

import {
  ActiveChargesAddBillrequest,
  ActiveChargesEditBillrequest,
  IInvoiceLayout,
  IInvoiceOverride,
  InvoiceCashOut,
  InvoiceDetails,
  InvoiceTotals,
  RegisterInvoice,
} from '../../models';
import { TemporaryInvoiceConfig } from '../../models/objects/temporary-invoice-config';
import { Hooks } from '../../helpers';

export const createRequest = createAction(
  '[Temporary Invoice] Create Request',
  props<{ config: TemporaryInvoiceConfig; hooks?: Hooks }>(),
);
export const createSuccess = createAction(
  '[Temporary Invoice] Create Success',
  props<{ details: InvoiceDetails }>(),
);
export const createFailure = createAction(
  '[Temporary Invoice] Create Failure',
  props<{ error: any }>(),
);

export const loadRequest = createAction(
  '[Temporary Invoice] Load Request',
  props<{ temporary_invoice_id: number; advance_invoice_id?: number }>(),
);
export const loadSuccess = createAction(
  '[Temporary Invoice] Load Success',
  props<{ details: InvoiceDetails }>(),
);
export const loadFailure = createAction(
  '[Temporary Invoice] Load Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Temporary Invoice] Delete Request',
  props<{ temporary_invoice_id: number }>(),
);
export const deleteSuccess = createAction('[Temporary Invoice] Delete Success');
export const deleteFailure = createAction(
  '[Temporary Invoice] Delete Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Temporary Invoice] Update Request',
  props<{ temporary_invoice_id: number; payload: Partial<InvoiceDetails> }>(),
);
export const updateSuccess = createAction(
  '[Temporary Invoice] Update Success',
  props<{ details: InvoiceDetails }>(),
);
export const updateFailure = createAction(
  '[Temporary Invoice] Update Failure',
  props<{ error: any }>(),
);

export const addRowRequest = createAction(
  '[Temporary Invoice] Add Row Request',
  props<{
    temporary_invoice_id: number;
    payload: ActiveChargesAddBillrequest;
  }>(),
);
export const addRowSuccess = createAction(
  '[Temporary Invoice] Add Row Success',
);
export const addRowFailure = createAction(
  '[Temporary Invoice] Add Row Failure',
  props<{ error: any }>(),
);

export const updateRowRequest = createAction(
  '[Temporary Invoice] Update Row Request',
  props<{
    temporary_invoice_id: number;
    payload: ActiveChargesEditBillrequest;
  }>(),
);
export const updateRowSuccess = createAction(
  '[Temporary Invoice] Update Row Success',
);
export const updateRowFailure = createAction(
  '[Temporary Invoice] Update Row Failure',
  props<{ error: any }>(),
);

export const removeRowRequest = createAction(
  '[Temporary Invoice] Remove Row Request',
  props<{ temporary_invoice_id: number; bill_row_id: number[] }>(),
);
export const removeRowSuccess = createAction(
  '[Temporary Invoice] Remove Row Success',
);
export const removeRowFailure = createAction(
  '[Temporary Invoice] Remove Row Failure',
  props<{ error: any }>(),
);

export const createOverrideRequest = createAction(
  '[Temporary Invoice] Create Override Request',
  props<{ payload: IInvoiceOverride; temporary_invoice_id: number }>(),
);
export const createOverrideSuccess = createAction(
  '[Temporary Invoice] Create Override Success',
);
export const createOverrideFailure = createAction(
  '[Temporary Invoice] Create Override Failure',
  props<{ error: any }>(),
);

export const createInvoiceRejectedRequest = createAction(
  '[Temporary Invoice] Create Invoice Rejected Request',
  props<{
    invoice_id: number;
    onSuccess?: () => void;
  }>(),
);
export const createInvoiceRejectedSuccess = createAction(
  '[Temporary Invoice] Create Invoice Rejected Success',
  props<{ details: InvoiceDetails }>(),
);
export const createInvoiceRejectedFailure = createAction(
  '[Temporary Invoice] Create Invoice Rejected Failure',
  props<{ error: any }>(),
);

export const updateOverrideRequest = createAction(
  '[Temporary Invoice] Update Override Request',
  props<{ payload: IInvoiceOverride; temporary_invoice_id: number }>(),
);
export const updateOverrideSuccess = createAction(
  '[Temporary Invoice] Update Override Success',
);
export const updateOverrideFailure = createAction(
  '[Temporary Invoice] Update Override Failure',
  props<{ error: any }>(),
);

export const removeOverrideRequest = createAction(
  '[Temporary Invoice] Remove Override Request',
  props<{ temporary_invoice_id: number; override_id: number }>(),
);
export const removeOverrideSuccess = createAction(
  '[Temporary Invoice] Remove Override Success',
);
export const removeOverrideFailure = createAction(
  '[Temporary Invoice] Remove Override Failure',
  props<{ error: any }>(),
);

export const updateInvoiceTotals = createAction(
  '[Temporary Invoice] Update Invoice Totals',
  props<{ totals: Partial<InvoiceTotals> }>(),
);

export const setUsingHolidayVoucher = createAction(
  '[Temporary Invoice] Set Using Holiday Voucher',
  props<{ usingHolidayVoucher: boolean }>(),
);

export const registerRequest = createAction(
  '[Temporary Invoice] Register Request',
  props<{
    invoice: InvoiceDetails;
    layout: IInvoiceLayout;
    invoiceCashOut: InvoiceCashOut;
    registerType: 'invoice' | 'receipt';
    config: TemporaryInvoiceConfig;
    properties: number[];
  }>(),
);
export const registerSuccess = createAction(
  '[Temporary Invoice] Register Success',
);
export const registerFailure = createAction(
  '[Temporary Invoice] Register Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Virtual Channel] Reset State');
