import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { ActivChargeService } from '@app/services';
import { NotificationService } from '@app/ui/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { effectHooks } from '../../helpers';

import * as featureActions from './actions';

@Injectable()
export class ActiveChargesStoreEffects {
  constructor(
    private dataService: ActivChargeService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  searchEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.SearchRequestAction>(
        featureActions.ActionTypes.SEARCH_REQUEST,
      ),
      switchMap((action: featureActions.SearchRequestAction) => {
        return this.dataService.load(action.payload.params).pipe(
          map(
            ({ data, meta }: IResponseSuccess) =>
              new featureActions.SearchSuccessAction({
                items: data,
                pagination: meta.pagination,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.SearchFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  loadPaginatedEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadPaginatedRequestAction>(
        featureActions.ActionTypes.LOAD_PAGINATED_REQUEST,
      ),
      switchMap((action: featureActions.LoadPaginatedRequestAction) => {
        return this.dataService.load(action.payload).pipe(
          map(
            ({ data, meta }: any) =>
              new featureActions.LoadPaginatedSuccessAction({
                items: data,
                pagination: meta.pagination,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadPaginatedFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  createEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateRequestAction>(
        featureActions.ActionTypes.CREATE_REQUEST,
      ),
      switchMap((action: featureActions.CreateRequestAction) => {
        const { activeCharge, onSuccess, onFailure } = action.payload;
        return this.dataService.create(activeCharge).pipe(
          effectHooks({ onSuccess, onFailure }),
          map(({ data }: any) => {
            return new featureActions.CreateSuccessAction({
              activeCharge: data[activeCharge.property_id[0]],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.CreateFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  addBillffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.AddBillRequestAction>(
        featureActions.ActionTypes.ADD_BILL_REQUEST,
      ),
      switchMap((action: featureActions.AddBillRequestAction) => {
        return this.dataService.addBill(action.payload.data).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.create_success', {
                param: this.translate.instant('extra'),
              }),
              type: 'success',
            });
            return new featureActions.AddBillSuccessAction();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.AddBillFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  editBillffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.EditBillRequestAction>(
        featureActions.ActionTypes.EDIT_BILL_REQUEST,
      ),
      switchMap((action: featureActions.EditBillRequestAction) => {
        return this.dataService.editBill(action.payload.data).pipe(
          map(() => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('extra'),
              }),
              type: 'success',
            });
            return new featureActions.EditBillSuccessAction();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.EditBillFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  deleteBillffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteBillRequestAction>(
        featureActions.ActionTypes.DELETE_BILL_REQUEST,
      ),
      switchMap((action: featureActions.DeleteBillRequestAction) => {
        return this.dataService
          .deleteBill(action.payload.generalInvoiceId, action.payload.chargeIds)
          .pipe(
            map(() => {
              return new featureActions.DeleteBillSuccessAction();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.DeleteBillFailureAction({ error }));
            }),
          );
      }),
    ),
  );

  deleteChargeffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteChargeRequestAction>(
        featureActions.ActionTypes.DELETE_CHARGE_REQUEST,
      ),
      switchMap((action: featureActions.DeleteChargeRequestAction) => {
        return this.dataService
          .deleteGeneralInvoice(action.payload.chargeId)
          .pipe(
            map(() => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.delete_success',
                  {
                    param: this.translate.instant('bill'),
                  },
                ),
                type: 'success',
              });
              return new featureActions.DeleteChargeSuccessAction({
                chargeId: action.payload.chargeId,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                new featureActions.DeleteChargeFailureAction({ error }),
              );
            }),
          );
      }),
    ),
  );

  moveBillsEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.MoveBillsRequestAction>(
        featureActions.ActionTypes.MOVE_BILL_REQUEST,
      ),
      switchMap((action: featureActions.MoveBillsRequestAction) => {
        const {
          invoice_id,
          bill_id,
          createGeneralInvoicePayload,
          onSuccess,
          onFailure,
        } = action.payload;
        return this.dataService
          .moveBills(invoice_id, bill_id, createGeneralInvoicePayload)
          .pipe(
            map(() => {
              if (onSuccess) {
                onSuccess();
              }

              return new featureActions.MoveBillsSuccessAction();
            }),
            catchError((error) => {
              if (onFailure) {
                onFailure();
              }

              this.errorHandler.handle(error);
              return of(new featureActions.MoveBillsFailureAction({ error }));
            }),
          );
      }),
    ),
  );
}
