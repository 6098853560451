import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NzModalService } from 'ng-zorro-antd/modal';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { TemporaryInvoiceComponent } from '../../components/temporary-invoice/temporary-invoice.component';
import { InvoiceDetails } from '../../models';
import { ExportService } from '../../services/export.service';
import { TemporaryInvoiceService } from '../../services/temporary-invoice.service';
import { InvoiceRegisterResolverService } from '../../shared/invoice/services/invoice-register-resolver.service';

import * as fromActions from './actions';
import { effectHooks } from '../../helpers';

@Injectable()
export class TemporaryInvoiceStoreEffects {
  constructor(
    private dataService: TemporaryInvoiceService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private invoiceRegisterResolverService: InvoiceRegisterResolverService,
    private exportService: ExportService,
    private modalService: NzModalService,
  ) {}

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ config, hooks }) =>
        this.dataService.create(config).pipe(
          effectHooks(hooks),
          map(({ data }: IResponseSuccess<InvoiceDetails[]>) => {
            const { penalty } = config;

            return fromActions.createSuccess({
              details: { ...data[0], penalty },
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ temporary_invoice_id }) =>
        this.dataService.delete(temporary_invoice_id).pipe(
          map(() => fromActions.deleteSuccess()),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ temporary_invoice_id, advance_invoice_id }) =>
        this.dataService.load(temporary_invoice_id, advance_invoice_id).pipe(
          map(({ data }: IResponseSuccess<InvoiceDetails[]>) =>
            fromActions.loadSuccess({ details: data[0] }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  createInvoiceRejected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createInvoiceRejectedRequest),
      switchMap(({ invoice_id, onSuccess }) =>
        this.dataService.createInvoiceRejected(invoice_id).pipe(
          map(({ data }: IResponseSuccess<InvoiceDetails[]>) => {
            if (onSuccess) {
              onSuccess();
            }
            return fromActions.createInvoiceRejectedSuccess({
              details: data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createInvoiceRejectedFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ temporary_invoice_id, payload }) =>
        this.dataService.update(temporary_invoice_id, payload).pipe(
          map(({ data }: IResponseSuccess<InvoiceDetails[]>) =>
            fromActions.updateSuccess({ details: data[0] }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  createRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addRowRequest),
      switchMap(({ temporary_invoice_id, payload }) => {
        return this.dataService.createRow(temporary_invoice_id, payload).pipe(
          map(() => {
            return fromActions.addRowSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.addRowFailure(error));
          }),
        );
      }),
    ),
  );

  updateRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRowRequest),
      switchMap(({ temporary_invoice_id, payload }) => {
        return this.dataService.updateRow(temporary_invoice_id, payload).pipe(
          map(() => fromActions.updateRowSuccess()),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateRowFailure(error));
          }),
        );
      }),
    ),
  );

  deleteRow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeRowRequest),
      switchMap(({ temporary_invoice_id, bill_row_id }) => {
        return this.dataService
          .deleteRows(temporary_invoice_id, bill_row_id)
          .pipe(
            map(() => fromActions.removeRowSuccess()),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.removeRowFailure(error));
            }),
          );
      }),
    ),
  );

  createOverride$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createOverrideRequest),
      switchMap(({ temporary_invoice_id, payload }) => {
        return this.dataService
          .createOverride(temporary_invoice_id, payload)
          .pipe(
            map(() => {
              return fromActions.createOverrideSuccess();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.createOverrideFailure(error));
            }),
          );
      }),
    ),
  );

  updateOverride$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateOverrideRequest),
      switchMap(({ temporary_invoice_id, payload }) => {
        return this.dataService
          .updateOverride(temporary_invoice_id, payload)
          .pipe(
            map(() => fromActions.updateOverrideSuccess()),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.updateOverrideFailure(error));
            }),
          );
      }),
    ),
  );

  deleteOverride$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeOverrideRequest),
      switchMap(({ temporary_invoice_id, override_id }) => {
        return this.dataService
          .deleteOverride(temporary_invoice_id, override_id)
          .pipe(
            map(() => fromActions.removeOverrideSuccess()),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.removeOverrideFailure(error));
            }),
          );
      }),
    ),
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.registerRequest),
      switchMap(({ type, ...action }) => {
        const { layout, registerType, invoiceCashOut, config, properties } =
          action;

        const isFiscalPrinterRegister =
          layout?.fiscalprinters?.length && registerType === 'receipt';

        let registerParams = {
          ...action,
          properties,
          registerService: this.dataService,
          registerExtraPayload: null,
        };

        if (config?.bills_city_tax?.length) {
          registerParams = {
            ...registerParams,
            registerExtraPayload: { bills_city_tax: config?.bills_city_tax },
          };
        }

        return this.invoiceRegisterResolverService
          .register(registerParams)
          .pipe(
            map((invoice) => {
              if (!isFiscalPrinterRegister) {
                this.exportService.printInvoice({
                  invoice_id: invoice.id,
                  download: 0,
                  invoiceCashOut,
                });
              }

              return fromActions.registerSuccess();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.registerFailure(error));
            }),
          );
      }),
    ),
  );
}
