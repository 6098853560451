import { InvoiceDetails, InvoiceTotals } from '../../models';

export interface State {
  details: InvoiceDetails;
  totals: InvoiceTotals;
  usingHolidayVoucher: boolean;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  details: null,
  totals: null,
  usingHolidayVoucher: false,
  isLoading: false,
  error: null,
};
