import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectState = createFeatureSelector<State>('temporaryInvoice');

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectInvoice = createSelector(
  selectState,
  (state: State) => state.details,
);

export const selectInvoiceTotals = createSelector(
  selectState,
  (state: State) => state.totals,
);

export const selectUsingHolidayVoucher = createSelector(
  selectState,
  (state: State) => state.usingHolidayVoucher,
);
