import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import { Action } from '@ngrx/store';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import {
  ActiveChargesAddBillrequest,
  ActiveChargesEditBillrequest,
  CreateActiveChargeRequest,
  Invoice,
  InvoiceDetails,
} from '../../models';
import { SearchParams } from '../../models/objects/search-params';
import { ActiveChargeUpdateListItemRequest } from '../../models/requests/active-charges-update-list-item-request';

export enum ActionTypes {
  SEARCH_REQUEST = '[ActiveCharges] Search Request',
  SEARCH_FAILURE = '[ActiveCharges] Search Failure',
  SEARCH_SUCCESS = '[ActiveCharges] Search Success',

  LOAD_PAGINATED_REQUEST = '[ActiveCharges] Load Paginated Request',
  LOAD_PAGINATED_FAILURE = '[ActiveCharges] Load Paginated Failure',
  LOAD_PAGINATED_SUCCESS = '[ActiveCharges] Load Paginated Success',

  CREATE_REQUEST = '[ActiveCharges] Create Request',
  CREATE_FAILURE = '[ActiveCharges] Create Failure',
  CREATE_SUCCESS = '[ActiveCharges] Create Success',

  ADD_BILL_REQUEST = '[ActiveCharges] Add Bill Request',
  ADD_BILL_FAILURE = '[ActiveCharges] Add bill Failure',
  ADD_BILL_SUCCESS = '[ActiveCharges] Add bill Success',

  EDIT_BILL_REQUEST = '[ActiveCharges] Edit Bill Request',
  EDIT_BILL_FAILURE = '[ActiveCharges] Edit bill Failure',
  EDIT_BILL_SUCCESS = '[ActiveCharges] Edit bill Success',

  DELETE_CHARGE_REQUEST = '[ActiveCharges] Delete Charge Request',
  DELETE_CHARGE_FAILURE = '[ActiveCharges] Delete Charge Failure',
  DELETE_CHARGE_SUCCESS = '[ActiveCharges] Delete Charge Success',

  DELETE_BILL_REQUEST = '[ActiveCharges] Delete Bill Request',
  DELETE_BILL_FAILURE = '[ActiveCharges] Delete bill Failure',
  DELETE_BILL_SUCCESS = '[ActiveCharges] Delete bill Success',

  UPDATE_LIST_ITEM_REQUEST = '[ActiveCharges] Update List Item Request',
  UPDATE_LIST_ITEM_FAILURE = '[ActiveCharges] Update List Item Failure',
  UPDATE_LIST_ITEM_SUCCESS = '[ActiveCharges] Update List Item Success',

  MOVE_BILL_REQUEST = '[ActiveCharges] Move Bill Request',
  MOVE_BILL_FAILURE = '[ActiveCharges] Move Bill Failure',
  MOVE_BILL_SUCCESS = '[ActiveCharges] Move Bill Success',

  RESET_STATE = '[ActiveCharges] Reset State',

  RESET_SEARCH_RESULTS = '[ActiveCharges] Reset Search Results',
}

export class UpdateListItemRequest implements Action {
  readonly type = ActionTypes.UPDATE_LIST_ITEM_REQUEST;
  constructor(public payload: { data: ActiveChargeUpdateListItemRequest }) {}
}

export class UpdateListItemSuccess implements Action {
  readonly type = ActionTypes.UPDATE_LIST_ITEM_SUCCESS;
  constructor(public payload: { data: ActiveChargeUpdateListItemRequest }) {}
}

export class UpdateListItemFailure implements Action {
  readonly type = ActionTypes.UPDATE_LIST_ITEM_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteChargeRequestAction implements Action {
  readonly type = ActionTypes.DELETE_CHARGE_REQUEST;
  constructor(public payload: { chargeId: number }) {}
}

export class DeleteChargeFailureAction implements Action {
  readonly type = ActionTypes.DELETE_CHARGE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteChargeSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_CHARGE_SUCCESS;
  constructor(public payload: { chargeId: number }) {}
}

export class DeleteBillRequestAction implements Action {
  readonly type = ActionTypes.DELETE_BILL_REQUEST;
  constructor(
    public payload: { generalInvoiceId: number; chargeIds: number[] },
  ) {}
}

export class DeleteBillFailureAction implements Action {
  readonly type = ActionTypes.DELETE_BILL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteBillSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_BILL_SUCCESS;
}

export class EditBillRequestAction implements Action {
  readonly type = ActionTypes.EDIT_BILL_REQUEST;
  constructor(public payload: { data: ActiveChargesEditBillrequest }) {}
}

export class EditBillFailureAction implements Action {
  readonly type = ActionTypes.EDIT_BILL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class EditBillSuccessAction implements Action {
  readonly type = ActionTypes.EDIT_BILL_SUCCESS;
}

export class AddBillRequestAction implements Action {
  readonly type = ActionTypes.ADD_BILL_REQUEST;
  constructor(public payload: { data: ActiveChargesAddBillrequest }) {}
}

export class AddBillFailureAction implements Action {
  readonly type = ActionTypes.ADD_BILL_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class AddBillSuccessAction implements Action {
  readonly type = ActionTypes.ADD_BILL_SUCCESS;
}

export class SearchRequestAction implements Action {
  readonly type = ActionTypes.SEARCH_REQUEST;
  constructor(
    public payload: {
      params: SearchParams;
    },
  ) {}
}

export class SearchFailureAction implements Action {
  readonly type = ActionTypes.SEARCH_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class SearchSuccessAction implements Action {
  readonly type = ActionTypes.SEARCH_SUCCESS;
  constructor(
    public payload: {
      items: Invoice[];
      pagination: IPagination;
    },
  ) {}
}

export class LoadPaginatedRequestAction implements Action {
  readonly type = ActionTypes.LOAD_PAGINATED_REQUEST;
  constructor(public payload: SearchParams) {}
}

export class LoadPaginatedFailureAction implements Action {
  readonly type = ActionTypes.LOAD_PAGINATED_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadPaginatedSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_PAGINATED_SUCCESS;
  constructor(
    public payload: {
      items: Invoice[];
      pagination: IPagination;
    },
  ) {}
}

export class CreateFailureAction implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public payload: { error: Error }) {}
}

export class CreateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REQUEST;
  constructor(
    public payload: {
      activeCharge: CreateActiveChargeRequest;
      onSuccess?: (response: IResponseSuccess) => void;
      onFailure?: () => void;
    },
  ) {}
}

export class CreateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
  constructor(public payload: { activeCharge: InvoiceDetails }) {}
}

export class MoveBillsFailureAction implements Action {
  readonly type = ActionTypes.MOVE_BILL_FAILURE;
  constructor(public payload: { error: Error }) {}
}

export class MoveBillsRequestAction implements Action {
  readonly type = ActionTypes.MOVE_BILL_REQUEST;
  constructor(
    public payload: {
      invoice_id?: number;
      createGeneralInvoicePayload?: CreateActiveChargeRequest;
      bill_id: number[];
      onSuccess?: () => void;
      onFailure?: () => void;
    },
  ) {}
}

export class MoveBillsSuccessAction implements Action {
  readonly type = ActionTypes.MOVE_BILL_SUCCESS;
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export class ResetSearchResultsAction implements Action {
  readonly type = ActionTypes.RESET_SEARCH_RESULTS;
}

export type Actions =
  | SearchRequestAction
  | SearchFailureAction
  | SearchSuccessAction
  | LoadPaginatedRequestAction
  | LoadPaginatedFailureAction
  | LoadPaginatedSuccessAction
  | ResetSuccessAction
  | ResetSearchResultsAction
  | CreateFailureAction
  | CreateRequestAction
  | CreateSuccessAction
  | AddBillRequestAction
  | AddBillSuccessAction
  | AddBillFailureAction
  | DeleteBillRequestAction
  | DeleteBillSuccessAction
  | DeleteBillFailureAction
  | DeleteChargeRequestAction
  | DeleteChargeSuccessAction
  | DeleteChargeFailureAction
  | UpdateListItemRequest
  | UpdateListItemSuccess
  | UpdateListItemFailure
  | MoveBillsFailureAction
  | MoveBillsRequestAction
  | MoveBillsSuccessAction;
