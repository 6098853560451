import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    case ActionTypes.DELETE_CHARGE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.DELETE_CHARGE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.DELETE_CHARGE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_PAGINATED_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.SEARCH_REQUEST: {
      return {
        ...state,
        searchIsLoading: true,
      };
    }

    case ActionTypes.SEARCH_SUCCESS: {
      return {
        ...state,
        searchResults: action.payload.items,
        searchIsLoading: false,
        searchPagination: action.payload.pagination,
        error: null,
      };
    }

    case ActionTypes.SEARCH_FAILURE: {
      return {
        ...state,
        searchIsLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_PAGINATED_SUCCESS: {
      const { items, pagination } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        pagination,
        paginatedResults: {
          ...state.paginatedResults,
          [pagination.current_page]: items,
        },
      };
    }

    case ActionTypes.LOAD_PAGINATED_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.CREATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.CREATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.UPDATE_LIST_ITEM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.UPDATE_LIST_ITEM_FAILURE: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.RESET_SEARCH_RESULTS: {
      return {
        ...state,
        searchResults: initialState.searchResults,
        searchPagination: initialState.searchPagination,
      };
    }

    default: {
      return state;
    }
  }
}
