import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { Invoice } from '../../models';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getActiveChargesPagination = (state: State): IPagination =>
  state.pagination;

export const selectActiveChargeState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('ActiveCharges');

export const selectActiveChargesIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(selectActiveChargeState, getIsLoading);

export const selectActiveChargesError: MemoizedSelector<
  object,
  any
> = createSelector(selectActiveChargeState, getError);

export const selectActiveChargesPagination: MemoizedSelector<
  object,
  IPagination
> = createSelector(selectActiveChargeState, getActiveChargesPagination);

export const selectSearchPagination: MemoizedSelector<
  object,
  IPagination
> = createSelector(
  selectActiveChargeState,
  (state: State) => state.searchPagination,
);

export const selectPaginatedActiveCharges: MemoizedSelector<
  object,
  { [page: number]: Invoice[] }
> = createSelector(
  selectActiveChargeState,
  (state: State) => state.paginatedResults,
);

export const selectActiveCharges: MemoizedSelector<
  object,
  Invoice[]
> = createSelector(
  selectPaginatedActiveCharges,
  (paginatedResults: { [page: number]: Invoice[] }) =>
    Object.values(paginatedResults || {}).reduce(
      (invoices, activeChargesOfPage) =>
        (invoices = [...invoices, ...activeChargesOfPage]),
      [],
    ),
);

export const selectSearchResults: MemoizedSelector<
  object,
  Invoice[]
> = createSelector(
  selectActiveChargeState,
  (state: State) => state.searchResults,
);

export const selectSearchLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectActiveChargeState,
  (state: State) => state.searchIsLoading,
);
