import { IPagination } from '@app/core/models/api/pagination/pagination.model';

import { Invoice } from '../../models';

export interface State {
  isLoading?: boolean;
  searchIsLoading?: boolean;
  searchPagination: IPagination;
  error?: any;
  pagination: IPagination;
  searchResults: Invoice[];
  paginatedResults: { [page: number]: Invoice[] };
}

export const initialState: State = {
  isLoading: false,
  searchIsLoading: false,
  error: null,
  pagination: null,
  searchResults: null,
  searchPagination: null,
  paginatedResults: null,
};
